














import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import shopcart from './InsShoppingCart.vue';
import login from './InsLogin.vue';
// import search from './InsSearch.vue';

@Component({
  components: {
    shopcart,
    login
    // ,
    // search
  }
})
export default class InsHandleGroup extends Vue {
  // private searchKey: string = '';
  // @Watch('searchKey')
  // onSearchKeyChange () {
  //   this.$store.dispatch('setSearchKey', this.searchKey);
  // }
}
