











import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class InsLangSelect extends Vue {
  private changLange (lang) {
    this.$Api.member.setUILanguage(lang === 'zh' ? 'C' : 'E').then((result) => {
      this.$i18n.locale = lang;
      // console.log(this.hhh, this.$t('test'),'test');
      localStorage.setItem('locale', lang);

      this.$message({
        message: this.$t('changeLangSuccess') as string,
        type: 'success'
      });
    }).catch((error) => {
      console.log(error);
    });
  }
}
