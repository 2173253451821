























import { Component, Prop, Vue } from 'vue-property-decorator';
import logo from '@/components/base/pc/InsLogo.vue';
import handleGroup from '@/components/business/pc/header/InsHandleGroup.vue';
import switchGroup from '@/components/business/pc/header/InsSwitchGroup.vue';

@Component({
  components: {
    InsMenu: () => import('@/components/business/pc/header/InsMenu.vue'),
    InsLogo: () => import('@/components/base/pc/InsLogo.vue'),
    handleGroup,
    switchGroup,
    elMenu: () => import('@/components/business/pc/header/InsElMenu.vue')
  }
})
export default class InsHeader extends Vue {
  @Prop() private msg!: string;
  // private hhh: any = this.$t('test');
  isshow: boolean=false;
  showLang () {
    if (localStorage.locale === 'E') this.isshow = true;
  }
  get HomeLogo () {
    if (localStorage.locale === 'E') return '/cmx4/E_logo.jpg';
    if (localStorage.locale === 'C') return '/cmx4/C_logo.jpg';
    if (localStorage.locale === 'S') return '/cmx4/S_logo.jpg';
  }
  getMenu () {
    this.$Api.promotion.getMenu().then((result) => {
      this.$store.dispatch('setHeaderMenus', result.ReturnValue.HeaderMenus);
      this.$store.dispatch('setFooterMenus', result.ReturnValue.FooterMenus);
    }).catch((error) => {
      console.log(error);
    });
  }

  changeLang (lang) {
    // this.$i18n.locale = lang;
    // localStorage.setItem('locale', lang);
    // window.location.reload();

    this.$Api.member.setUILanguage(lang).then((result) => {
      this.$i18n.locale = lang;
      // console.log(this.hhh, this.$t('test'),'test');
      localStorage.setItem('locale', lang);

      window.location.reload();
    }).catch((error) => {
      console.log(error);
    });
  }

  mounted () {
    this.getMenu();
    this.showLang();
  }
}
